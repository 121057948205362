import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Background from '../../assets/svgs/background.svg';
import Name from '../../assets/svgs/name.svg';
import { media } from '../../utils/media-queries';
import Visionary from '../../assets/svgs/visionary.svg';
import VisionaryMobile from '../../assets/svgs/visionary_mobile.svg';
import Parallax from '../atoms/parallax';

const Header = () => (
  <StyledHeader>
    <Link to="/">
      <div className="logo">
        <h1>Georgia Susan April</h1>
        <div className="svg-logo">
          <Background />
          <Name />
        </div>
      </div>
    </Link>
    <div className="visionary-container">
      <Parallax speed={0}>
        <Visionary />
        <VisionaryMobile />
      </Parallax>
    </div>
  </StyledHeader>
);
const StyledHeader = styled.header`
  background: white;
  position: fixed;
  width: 100vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-XXXS) 0;
  padding-top: var(--spacing-XXS);

  .logo {
    display: flex;
    justify-content: center;
    position: relative;

    h1 {
      visibility: hidden;
      position: absolute;
    }

    .svg-logo {
      display: flex;
      justify-content: center;

      .name {
        position: absolute;
        height: 110%;
      }

      .background {
        width: 90vw;
        height: 100%;
      }
    }
  }

  .visionary-container {
    margin: 0 auto;

    .visionary {
      display: none;
    }

    .visionary-mobile {
      width: 70vw;
    }
  }

  @media ${media.M} {
    .svg-logo {
      width: 60vw;
    }

    .visionary-container {
      .visionary {
        display: block;
        width: 50vw;
      }

      .visionary-mobile {
        display: none;
      }
    }
  }
`;

export default Header;
