import { useViewportScroll } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useWindowSize } from './useWindowSize';

export const useScrollProgress = () => {
  const size = useWindowSize();
  const { scrollYProgress } = useViewportScroll();
  const [scrollProgress, setScrollProgress] = useState();

  useEffect(() => {
    scrollYProgress.onChange(() => setScrollProgress(scrollYProgress.current));
  }, [size, scrollYProgress]);

  return scrollProgress;
};

// USAGE
// returns a number between 0 and 1
// const scrollProgress = useScrollProgress();
