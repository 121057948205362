import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Instagram from '../../assets/svgs/instagram.svg';
import LinkedIn from '../../assets/svgs/linkedin.svg';
import Email from '../../assets/svgs/mail.svg';
import { media } from '../../utils/media-queries';

const Contact = () => {
  const { footer } = useStaticQuery(graphql`
    query ColorsTitles {
      footer: allSanityFooter {
        nodes {
          linkedin
          instagram
          email
        }
      }
    }
  `);

  return (
    <StyledItem>
      <a href={`mailto:${footer.nodes[0].email}`}>
        <Email />
      </a>
      <a href={footer.nodes[0].linkedin} target="_blank" rel="noreferrer">
        <LinkedIn />
      </a>
      <a href={footer.nodes[0].instagram} target="_blank" rel="noreferrer">
        <Instagram />
      </a>
      <Link to="/legal/" className="legal copy-small">
        Disclaimer & <br />
        Privacy Policy
      </Link>
    </StyledItem>
  );
};

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-XXS) 0;
  cursor: none;

  * {
    cursor: none;
  }

  svg {
    width: 7vw;
    height: 100%;
  }

  .email,
  .linkedin,
  .instagram {
    margin-right: var(--spacing-S);
  }

  .email,
  .linkedin,
  .instagram {
    &:hover {
      fill: white;
    }
  }

  .legal {
    padding-right: var(--spacing-XXS);

    &:hover {
      color: white;
    }
  }

  @media ${media.M} {
    justify-content: flex-end;

    svg {
      width: 2.5vw;
    }

    .email,
    .linkedin,
    .instagram {
      margin-right: var(--spacing-XS);
    }
  }
`;

export default Contact;
