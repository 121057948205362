import { createGlobalStyle } from 'styled-components';
import { media } from '../utils/media-queries';

const GlobalStyles = createGlobalStyle`

  html {
    font-family: var(--font-1);
    color: white;
    background: var(--bg-color);
    font-size: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  body {
    font-size: var(--copy);
    line-height: var(--line-height);
    background: var(--yellow);
    overscroll-behavior-y: none;
    cursor: none;
    overflow-x: hidden;

    * {
      cursor: none;
    }
  }

  /* Images */

  .gatsby-image-wrapper {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }

  /* Layout */

  .spacing-outer {
    margin: var(--v-spacing-S);
  }

  .spacing-inner {
    padding: var(--v-spacing-M);
  }

  .spacing-removed {
    margin-left: calc(var(--v-spacing-M) * -1);
    margin-bottom: calc(var(--v-spacing-M) * -1);
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    grid-gap: var(--v-spacing-S);
  }

  .copy-small {
    font-size: var(--copy-small);
    color: black;
    line-height: 1.1;
  }

  .right {
    .img {
      grid-area: 1 / 2 / 1 / 7;
    }

    .copy {
      grid-area: 1 / 7 / 1 / 12;
    }
  }

  .left {
    .img {
      grid-area: 1 / 7 / 1 / 12;
    }

    .copy {
      grid-area: 1 / 2 / 1 / 7;
    }
  }

  .center {
    .img {
        grid-column: 3 / 11;
      }

      .copy {
        grid-column: 3 / 10 ;
      }
  }

  @media ${media.M} {
    .right {
      .img {
        grid-area: 1 / 3 / 1 / 7;
      }

      .copy {
        grid-area: 1 / 7 / 1 / 11;
      }
    }

    .left {
      .img {
        grid-area: 1 / 7 / 1 / 12;
      }

      .copy {
        grid-area: 1 / 3 / 1 / 7;
      }
    }

    .center {
      .img {
        grid-column: 5 / 9;
      }

      .copy {
        grid-column: 5 / 10 ;
      }
    }
  }

`;

export default GlobalStyles;
