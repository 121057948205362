import React from 'react';
import styled from 'styled-components';
import Contact from '../atoms/contact';

const Footer = () => (
  <StyledFooter>
    <Contact />
  </StyledFooter>
);

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export default Footer;
