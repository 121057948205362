import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useScrollProgress } from '../../hooks/useScrollProgress';

const Parallax = ({ speed, children, className }) => {
  const scrollProgress = useScrollProgress();

  return (
    <motion.div
      style={{ y: scrollProgress * speed, z: 70 }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

Parallax.propTypes = {
  speed: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Parallax;
